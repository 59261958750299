import React from 'react';

import SEO from '../components/SEO';
import Paragraph from '../components/shared/Paragraph';

export default () => (
  <>
    <SEO title="About Me" keywords={['react', 'javascript', 'web']} />
    <Paragraph>
      Hello, my name is Filip and I am from Macedonia. I{' '}
      <span role="img" aria-label="love">
        ❤️
      </span>{' '}
      all things web related, especially Progressive Web Apps. I mostly do
      Frontend development (JavaScript, Angular, React, Redux...) but I do enjoy
      Backend development as well (NodeJS, GraphQL, NestJS...).
    </Paragraph>
    <Paragraph>
      In my opinion it's important as a developer to stay curious in order to
      always keep up with the industry. Also it is important to be a team player
      beacuse it is a key part in building great things.
    </Paragraph>
  </>
);
